<template>
  <div class="tick-pay-choice">
    <Heading level="4">Поточне авто</Heading>
    <CurrentAuto
      class="tick-pay-choice__title"
      :current-auto="currentAutoTicket"
      camera-status="ticket_found"
      :auto-plate-number="currentAutoTicket.auto_registration_plate"
      :country-auto-registration-plate="currentAutoTicket.country_registration"
    />

    <Heading level="4" class="tick-pay-choice__subtitle">
      {{ $t(`payment_choice.payment_method`) }}
    </Heading>
    <div class="tick-pay-choice__money-btns">
      <ButtonBase
        v-for="item in paymentMethods"
        :key="item.name"
        :class="[
          item.class,
          {
            selected: selectedPaymentMethod === item.name,
          },
        ]"
        size="m"
        color="white"
        @click.native="selectPaymentMethodHandler(item)"
      >
        <svg-icon :name="`pay-${item.name}`"></svg-icon>
        <span class="tick-pay-choice__btn-text">
          {{ $t(`payment_choice.${item.name}`) }}
        </span>
      </ButtonBase>
    </div>
    <Heading level="3" class="tick-pay-choice__subtitle-price">
      {{ $t('payment_choice.price') }} {{ currentAutoTicket.ticket_price }} грн.
    </Heading>
    <div class="tick-pay-choice__price-btns">
      <ButtonBase color="white" size="md-bg" @click.native="goBack">
        {{ $t('payment_choice.back') }}
      </ButtonBase>
      <ButtonBase
        color="blue"
        size="md-bg"
        :disabled="!selectedPaymentMethod"
        @click.native="openModal()"
      >
        {{ $t('payment_choice.to_pay') }}
      </ButtonBase>
    </div>

    <Popup :dialog.sync="modal" class="popup" is-persistent>
      <Loader />
      <Heading level="4" class="popup__title"
        >Очікується оплата {{ selectedPaymentMethodTitle }}</Heading
      >
      <div class="popup__subtitle">Квиток сплачений ?</div>
      <div class="popup__buttons-box">
        <ButtonBase
          color="white"
          size="md-bg"
          class="popup__btn"
          @click.native="closeModal"
        >
          <span class="new-ticket__btn-titles">
            {{ $t('payment_choice.cancel') }}
          </span>
        </ButtonBase>
        <ButtonBase
          color="blue"
          size="md-bg"
          class="popup__btn"
          @click.native="goToPay"
        >
          <template v-if="!loadingPaymentBtn">
            {{ $t('payment_choice.done') }}
          </template>
          <Loader v-else color="white" size="sm" />
        </ButtonBase>
      </div>
    </Popup>
  </div>
</template>

<script>
import { setToLS, getFromLS, isLSHasItem } from '@/library/helpers';

import Heading from '@/elements/Heading/Heading.vue';
import ButtonBase from '@/elements/Buttons/ButtonBase.vue';

import CurrentAuto from '@/components/CurrentAuto/CurrentAuto.vue';

import Popup from '@/elements/Popup/Popup.vue';
import Loader from '@/elements/Loader/Loader.vue';

export default {
  components: {
    Heading,
    ButtonBase,
    Popup,
    Loader,
    CurrentAuto,
  },
  data() {
    return {
      currentAutoTicket: getFromLS('new-ticket'),
      loadingPaymentBtn: false,
      paymentMethods: [
        {
          name: 'cash',
          title: 'готівкою',
          class: 'tick-pay-choice__pay-cash',
        },
        {
          name: 'terminal',
          title: 'терміналом',
          class: 'tick-pay-choice__pay-terminal',
        },
      ],
      selectedPaymentMethod: '',
      modal: false,
      manualPayment: 1,
    };
  },
  computed: {
    selectedPaymentMethodTitle() {
      return this.selectedPaymentMethod
        ? this.paymentMethods.find(
            (method) => method.name === this.selectedPaymentMethod
          ).title
        : '';
    },
    newTicketData() {
      const ticket = {
        last_name: this.currentAutoTicket.last_name,
        first_name: this.currentAutoTicket.first_name,
        email: this.currentAutoTicket.email,
        telephone_number: this.currentAutoTicket.telephone_number,
        type_auto: this.currentAutoTicket.type_auto,
        country_registration_id: this.currentAutoTicket.country_registration.id,
        auto_registration_plate: this.currentAutoTicket.auto_registration_plate,
        car_brand_id: this.currentAutoTicket.car_brand.id,
        car_model_id: this.currentAutoTicket.car_model.id,
        count_of_passengers: this.currentAutoTicket.count_of_passengers,
        // source_sale: this.currentAutoTicket.source_sale,
        datetime: this.currentAutoTicket.datetime,
        time_interval: this.currentAutoTicket.time_interval,
        manual_payment: this.manualPayment,
      };

      if (this.currentAutoTicket.second_name) {
        ticket.second_name = this.currentAutoTicket.second_name;
      }

      return ticket;
    },
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'newTicketDate' });
    },
    selectPaymentMethodHandler(method) {
      this.selectedPaymentMethod = method.name;
    },
    openModal() {
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
    },
    updateNewTicketInLS(id) {
      if (isLSHasItem('new-ticket')) {
        const updatedNewTicket = {
          ...getFromLS('new-ticket'),
          id,
        };

        setToLS('new-ticket', updatedNewTicket);
      }
    },
    async goToPay() {
      this.loadingPaymentBtn = true;
      const { id: ticketId } = await this.$store.dispatch(
        'sendNewTicket',
        this.newTicketData
      );

      this.updateNewTicketInLS(ticketId);

      this.$router.push({
        name: 'newTicketPaid',
      });
    },
  },
};
</script>
<style lang="sass" scoped>
.tick-pay-choice__title
  margin-bottom: 40px

.tick-pay-choice__subtitle
  margin-bottom: 24px

.tick-pay-choice__subtitle,
.tick-pay-choice__subtitle-price
  padding-top: 32px
  border-top: 1px solid var(--primary-color-light-grey-2)

.tick-pay-choice__subtitle-price
  text-align: center
  margin-bottom: 40px

.tick-pay-choice__money-btns
  display: flex
  gap: 32px

.tick-pay-choice__btn-text
  padding-left: 15px
  font-size: 24px
  text-transform: unset!important

.tick-pay-choice__pay-cash,
.tick-pay-choice__pay-terminal
  text-transform: unset!important
  justify-content: flex-start!important
  padding-left: 25px
  box-shadow: 0px 32px 80px rgba(38, 55, 76, 0.05)

.tick-pay-choice__pay-cash
  color: #09A356!important
  fill: #09A356!important
  border: 1px solid #19BC6A!important

.tick-pay-choice__pay-terminal
  border: 1px solid var(--btn-color-blue)!important
  color: var(--btn-color-blue)!important
  fill: var(--btn-color-blue)!important

.tick-pay-choice__pay-cash:hover
  border-color: #09A356!important

.tick-pay-choice__pay-cash.selected
  background-color: rgba(9, 163, 86, 0.04)!important

.tick-pay-choice__pay-terminal.selected
  background-color: rgba(48, 83, 240, 0.04)!important

.tick-pay-choice__price-btns
  display: flex
  gap: 38px
  justify-content: center
  margin-bottom: 80px
</style>
