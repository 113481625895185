<template>
  <div class="auto-info auto-info__wrapper" :class="wrapperCssClasses">
    <div class="auto-info__header">
      <div class="auto-info__number">
        <div class="auto-info__flag">
          <img
            :src="`/images/flags/${getCountryAutoRegistration}.svg`"
            alt=""
          />
        </div>
        <span>
          {{ autoPlateNumber }}
        </span>
      </div>
      <div v-if="!isPaymentChoicePage && !isPaidPage">
        <div
          v-if="cameraStatus === 'ticket_found'"
          class="auto-info__ticket-status"
        >
          {{ $t('dashboard.ticket_is_buy') }}
        </div>
        <ButtonBase
          v-else
          color="blue"
          size="m"
          class="auto-info__ticket-buy"
          @click.native="goToNewTicket"
        >
          {{ $t('dashboard.ticket_to_buy') }}
        </ButtonBase>
      </div>
    </div>

    <div class="auto-info__content">
      <div class="auto-info__brand">
        <div class="auto-info__titles">{{ $t('dashboard.brand') }}</div>
        <div
          class="auto-info__values"
          :class="{
            'auto-info__values--not':
              !currentAuto || !!!currentAuto.car_brand.title,
          }"
        >
          {{ getCarBrand }}
        </div>
      </div>
      <div class="auto-info__model">
        <div class="auto-info__titles">{{ $t('dashboard.model') }}</div>
        <div
          class="auto-info__values"
          :class="{
            'auto-info__values--not':
              !currentAuto || !!!currentAuto.car_model.title,
          }"
        >
          {{ getCarModel }}
        </div>
      </div>
      <div class="auto-info__passangers">
        <div class="auto-info__titles">{{ $t('dashboard.passangers') }}</div>
        <div
          class="auto-info__values"
          :class="{
            'auto-info__values--not':
              !currentAuto || !!!currentAuto.count_of_passengers,
          }"
        >
          {{ getCountOfPassengers }}
        </div>
      </div>

      <div class="auto-info__empty"></div>

      <div class="auto-info__auto-type">
        <div class="auto-info__titles">
          {{ $t(`dashboard.type_of_auto_title`) }}
        </div>
        <div
          class="auto-info__values"
          :class="{
            'auto-info__values--not': !currentAuto || !!!currentAuto.type_auto,
          }"
        >
          {{ getTypeAuto }}
        </div>
      </div>

      <div class="auto-info__driver">
        <div class="auto-info__titles">{{ $t('dashboard.driver') }}</div>
        <div
          class="auto-info__driver-name"
          :class="{
            'auto-info__values--not': !currentAuto || !!!getDriverName,
          }"
        >
          {{ getDriverName }}
        </div>
      </div>

      <div class="auto-info__date">
        <div class="auto-info__titles">{{ $t('dashboard.date') }}</div>
        <div
          class="auto-info__values"
          :class="{
            'auto-info__values--not': !currentAuto || !!!currentAuto.datetime,
          }"
        >
          {{ getDate }}
        </div>
      </div>

      <div class="auto-info__time">
        <div class="auto-info__titles">{{ $t('dashboard.time') }}</div>
        <div
          class="auto-info__values"
          :class="{
            'auto-info__values--not':
              !currentAuto || !!!currentAuto.time_interval,
          }"
        >
          {{ gettimeInterval }}
        </div>
      </div>
      <span class="auto-info__devider"></span>
    </div>
  </div>
</template>

<script>
import ButtonBase from '@/elements/Buttons/ButtonBase.vue';
import { getDateFromString } from '@/library/dateFormat';
export default {
  components: {
    ButtonBase,
  },

  props: {
    currentAuto: {
      required: true,
      validator: (prop) => typeof prop === 'object' || prop === null,
    },
    cameraStatus: {
      type: String,
      required: true,
    },
    autoPlateNumber: {
      type: String,
      required: true,
    },
    countryAutoRegistrationPlate: {
      required: true,
      validator: (prop) => typeof prop === 'object' || prop === null,
    },
  },

  data() {
    return {
      defaultValues: {
        carBrand: '------',
        carModel: '------ ',
        countOfPassengers: '-',
        typeAuto: '------',
        driverName: '------ ------ ------',
        date: '--.--.----',
        timeInterval: '--:--',
      },
    };
  },

  computed: {
    getCountryAutoRegistration() {
      return this.countryAutoRegistrationPlate
        ? this.countryAutoRegistrationPlate.iso
        : 'default-flag';
    },
    getCarBrand() {
      return this.currentAuto?.car_brand.title || this.defaultValues.carBrand;
    },

    getCarModel() {
      return this.currentAuto?.car_model.title || this.defaultValues.carModel;
    },

    getCountOfPassengers() {
      return (
        this.currentAuto?.count_of_passengers ||
        this.defaultValues.countOfPassengers
      );
    },

    getTypeAuto() {
      return this.currentAuto
        ? this.$t(`dashboard.type_of_auto.${this.currentAuto.type_auto}`)
        : this.defaultValues.typeAuto;
    },

    getDriverName() {
      const secondName =
        this.currentAuto && this.currentAuto.second_name
          ? ` ${this.currentAuto.second_name}`
          : '';

      return this.currentAuto
        ? `${this.currentAuto.last_name} ${this.currentAuto.first_name}${secondName}`
        : this.defaultValues.driverName;
    },

    getDate() {
      return this.currentAuto?.datetime
        ? getDateFromString(this.currentAuto.datetime).date
        : this.defaultValues.date;
    },

    gettimeInterval() {
      return this.currentAuto?.time_interval
        ? this.currentAuto.time_interval
        : this.defaultValues.timeInterval;
    },

    isPaymentChoicePage() {
      return this.$route.name === 'newTicketPaymentChoice';
    },
    isPaidPage() {
      return this.$route.name === 'newTicketPaid';
    },
    wrapperCssClasses() {
      return {
        'is-no-ticket': this.cameraStatus === 'ticket_not_found',
        'payment-choice-page': this.isPaymentChoicePage,
        paid: this.isPaidPage,
      };
    },
  },
  methods: {
    goToNewTicket() {
      const carNumber =
        this.getCountryAutoRegistration === 'default-flag'
          ? {
              country: {
                id: 223,
              },
              value: this.autoPlateNumber,
            }
          : {
              country: {
                id: this.countryAutoRegistrationPlate.id,
              },
              value: this.autoPlateNumber,
            };

      this.$router.push({
        name: 'newTicketInfo',
        params: {
          carNumber,
        },
      });
    },
  },
};
</script>
<style lang="sass" scoped>
.auto-info__wrapper
  padding: 32px
  border-radius: 4px
  margin-bottom: 70px
  border: 1px solid rgba(40, 59, 89, 0.3)

  @media screen and (min-width: 1200px)
    padding: 32px 32px 7px

.auto-info__wrapper.is-no-ticket
  border-color: var(--border-color-blue)

  .auto-info__model:after
    background-color: var(--border-color-blue)

.auto-info__wrapper.paid
  border: 1px solid rgba(9, 163, 86, 0.4)
  .auto-info__model:after
    background-color: var(--primary-color-success)
    opacity: 0.4

.auto-info__header
  display: flex
  justify-content: space-between

.auto-info__number
  display: flex
  align-items: center
  gap: 15px
  font-size: 32px
  font-weight: 600
  color: var(--primary-color-dark-blue)

.auto-info__flag
  display: flex

  img
    width: 40px
    height: 27px

.auto-info__ticket-buy
  height: 40px !important
  font-size: 18px !important
  padding: 10px 35px
  border-radius: 2px !important
  text-transform: unset !important

.auto-info__content
  display: grid
  grid-template-columns: 26% 40% 18% 16%
  gap: 25px 0
  padding: 32px 0 0

  @media screen and (min-width: 1200px)
    grid-template-columns: 23% 38% 22% 17%
    gap: 30px 0

.auto-info__titles
  font-size: 16px
  line-height: 18.5px
  color: var(--primary-color-dark-blue)

.auto-info__model,
.auto-info__driver
  padding-right: 74px

.auto-info__model
  position: relative

.auto-info__model:after
  content: ''
  position: absolute
  height: 136px
  right: 54px
  top: -5px
  width: 1px
  background-color: var(--primary-color-light-grey-2)

.auto-info__driver-name
  color: var(--btn-color-blue)
  font-weight: 600
  font-size: 24px
  line-height: 32px

.auto-info__values,
.auto-info__values--not
  font-size: 20px
  line-height: 32px
  font-weight: 600
  color: var(--primary-color-dark-blue)

.auto-info__values--not
  color: var(--primary-color-dark-grey-2)

.auto-info__ticket-status
  font-weight: 600
  width: 250px
  text-align: center
  background: #ECF8F2
  color: var(--primary-color-success)
  padding: 10px

.auto-info__wrapper.payment-choice-page,
.auto-info__wrapper.paid
  padding: 32px 32px 8px

  @media screen and (max-width: 1199px)
    margin-bottom: 40px

  .auto-info__content
    grid-template-columns: 25% 30% 27% 18%
    row-gap: 25px

  .auto-info__auto-type
    grid-row-start: 1
    grid-column-start: 3

  .auto-info__driver
    grid-column: 1 / 3

  .auto-info__driver-name
    color: var(--primary-color-dark-blue)
</style>
